import { LineItems, OrderLineItem } from '../types/LineItems';

export const getOrderLineItemsSelectedIds = (
   lineItems: LineItems,
   selectedIds?: number[]
): OrderLineItem[] => {
   if (!lineItems) return [];

   if (!selectedIds) {
      return Object.keys(lineItems.orderLineItems).map(
         (key) => lineItems.orderLineItems[key]
      );
   }

   const orderLineItems = selectedIds?.map((selectedId) => {
      const key =
         Object.keys(lineItems.orderLineItems).find(
            (key) => lineItems.orderLineItems[key].id === selectedId
         ) || 0;

      return lineItems.orderLineItems[key];
   });

   return orderLineItems;
};
