import { ComplainProvider } from '../../contexts/ComplainContext';
import ComplainFlow from '../../components/Complains/ComplainFlow/ComplainFlow';
import Content from '../../components/Content/Content';

export default function ComplainPage() {
   return (
      <ComplainProvider>
         <Content>
            <ComplainFlow />
         </Content>
      </ComplainProvider>
   );
}
