import { useTranslation } from 'react-i18next';
import styles from './UnauthorizedPage.module.scss';
import Loading from '../../components/Loading/Loading';
import { useEffect } from 'react';
import useSiteConfig from '../../hooks/useSiteConfig';
import LoggedOutLayout from '../LoggedOutLayout/LoggedOutLayout';

export default function UnauthorizedPage() {
   const { t } = useTranslation('unauthorized');

   const { config } = useSiteConfig();

   let logoUrl = `${process.env.PUBLIC_URL}/img/logos/${config?.logo}`;
   if (config?.logo.includes('http')) {
      logoUrl = config.logo;
   }

   useEffect(() => {
      if (!config) return;

      setTimeout(() => {
         if (config?.storeUrl) {
            window.location.href = `${config.storeUrl || '/'}/customer/account/login/?ref=service&redirect=${encodeURIComponent(window.location.origin)}&type=login`;
         }
      }, 1000);
   }, [config]);

   if (!config) return null;

   return (
      <LoggedOutLayout config={config}>
         <div className={styles.unauthorized}>
            {logoUrl && <img src={logoUrl} alt={config.name} />}
            <p className={styles.description}>{t('description')}</p>
            {config.storeUrl && (
               <div>
                  <Loading />
               </div>
            )}
         </div>
      </LoggedOutLayout>
   );
}
