import { useTranslation } from 'react-i18next';
import styles from './NotFoundPage.module.scss';
import { NavLink } from 'react-router-dom';
import LoggedOutLayout from '../LoggedOutLayout/LoggedOutLayout';
import useSiteConfig from '../../hooks/useSiteConfig';

export default function NotFoundPage() {
   const { t } = useTranslation('not_found');
   const { config } = useSiteConfig();

   return (
      <LoggedOutLayout config={config}>
         <div className={styles.notFound}>
            <h1>{t('title')}</h1>
            <p className={styles.description}>{t('description')}</p>
            <NavLink to='/'>{t('go_home')}</NavLink>
         </div>
      </LoggedOutLayout>
   );
}
