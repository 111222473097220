export const formatPrice = (price: string, currency: string) => {
   if (!price) return '-';

   const priceNumber = parseFloat(price);

   if (isNaN(priceNumber)) {
      return price;
   }

   price = priceNumber.toFixed(2).replace('.', ',');
   price = price.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

   return `${price} ${currency}`;
};
