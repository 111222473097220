import React, { ReactNode, createContext, useState } from 'react';
import { Modal } from '../components/Modal/Modal';

type ModalContextType = {
   // eslint-disable-next-line no-unused-vars
   open: (content: ReactNode) => void;
   close: () => void;
};

export const ModalsContext = createContext<ModalContextType>({
   open: () => {},
   close: () => {},
});

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
   const [content, setContent] = useState<ReactNode | null>(null);

   const open = (content: ReactNode) => {
      setContent(content);
   };

   const close = () => {
      setContent(null);
   };

   return (
      <ModalsContext.Provider value={{ open, close }}>
         {children}

         <Modal content={content} />
      </ModalsContext.Provider>
   );
};
