import { useState, useEffect } from 'react';
import { fetchApi } from '../utils/fetchApi';
import { SiteConfig } from '../types/User';
import { useTranslation } from 'react-i18next';

const useSiteConfig = () => {
   const [config, setConfig] = useState<SiteConfig | null>(null);
   const { i18n } = useTranslation();

   useEffect(() => {
      const fetchSiteConfig = async () => {
         const data = await fetchApi('/api/site-config');

         let siteLanguage = data.data.language;

         const urlParams = new URLSearchParams(window.location.search);
         const lang = urlParams.get('lang');
         if (lang) {
            console.log('lang overwritten', lang);
            siteLanguage = lang;
         }

         i18n.changeLanguage(siteLanguage);
         setConfig(data.data);
      };

      fetchSiteConfig();
   }, []);

   return {
      config,
   };
};

export default useSiteConfig;
