export const fetchApi = async (url: string, options: RequestInit = {}) => {
   let apiBase = `https://api.aws.${window.location.hostname}`;

   if (window.location.hostname.endsWith('.local')) {
      apiBase = `https://api.aws.servicestaging.sparvinduer.dk`;
   }

   if (url.startsWith('http')) {
      apiBase = '';
   }

   if (process.env.REACT_APP_OVERRIDE_USER) {
      url = `${url}?override_user=${process.env.REACT_APP_OVERRIDE_USER}`;
   }

   let headers: HeadersInit = {
      'Content-Type': 'application/json',
   };

   if (options.headers) {
      headers = options.headers;
   }

   if (!options.credentials) {
      options.credentials = 'include';
   }

   const response = await fetch(`${apiBase}${url}`, {
      headers,
      ...options,
   });

   if (!response.ok) {
      console.error(response.statusText);
   }

   if (response.status === 401) {
      window.location.href = '/unauthorized';
   }

   return response.json();
};
