import { useTranslation } from 'react-i18next';
import styles from './OrderFiles.module.scss';
import Card from '../Card/Card';
import { useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { useParams } from 'react-router-dom';
import FileLine from '../FileLine/FileLine';
import CardLine from '../CardLine/CardLine';
import Loading from '../Loading/Loading';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';

interface Props {}

export default function OrderFiles({}: Props) {
   const { t } = useTranslation('orders');
   let { id } = useParams();

   const { fetchOrderFiles, orderFiles, loadingFiles } =
      useContext(UserContext);

   useEffect(() => {
      if (!id) return;

      fetchOrderFiles(id);
   }, [id]);

   if (!id) return null;

   const files = orderFiles?.[id];

   return (
      <Card title={t('files')} loading={loadingFiles}>
         {files && (
            <>
               {Object.keys(files.files).map((key) =>
                  files.files[parseInt(key)].map((file) => (
                     <FileLine key={file.filenameForUser} file={file} />
                  ))
               )}

               {files.pagination.pageNumber <
                  files.pagination.totalNumberOfPages && (
                  <CardLine>
                     <button
                        className={styles.loadmore}
                        onClick={() =>
                           fetchOrderFiles(
                              id as string,
                              files.pagination.pageNumber + 1
                           )
                        }
                     >
                        {t('show_more_files')}
                        <ArrowDownIcon />
                     </button>
                  </CardLine>
               )}
            </>
         )}

         {!loadingFiles &&
            files &&
            files.pagination.totalNumberOfFiles == 0 && (
               <CardLine>{t('no_files')}</CardLine>
            )}
      </Card>
   );
}
