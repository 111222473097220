import styles from './TableData.module.scss';

interface Props {
   children: React.ReactNode;
}

export default function TableData({ children }: Props) {
   return (
      <table className={styles.table}>
         <tbody>{children}</tbody>
      </table>
   );
}
