import { useContext, useEffect } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from '../../components/Sidebar/Sidebar';
import BannerNotice from '../../components/Notice/Notice';
import styles from './Layout.module.scss';
import Loading from '../../components/Loading/Loading';
import ConfigToHelmet from '../../utils/configToHelmet';

export default function Layout() {
   const { config, loading } = useContext(UserContext);

   const { pathname } = useLocation();

   useEffect(() => {
      window.scrollTo(0, 0);
   }, [pathname]);

   if (loading) {
      return (
         <div className={styles.loading}>
            <Loading large />
         </div>
      );
   }

   if (!config) {
      return <div className={styles.loading}>No brand found</div>;
   }

   return (
      <div className={styles.layout}>
         <ConfigToHelmet config={config} />
         <Sidebar />
         <main className={styles.main}>
            {config.notice.title && <BannerNotice notice={config.notice} />}
            <Outlet />
         </main>
      </div>
   );
}
