import { useTranslation } from 'react-i18next';
import styles from './SupportPage.module.scss';
import Content from '../../components/Content/Content';
import Card from '../../components/Card/Card';
import ContactForm from '../../components/ContactForm/ContactForm';
import CardLine from '../../components/CardLine/CardLine';
import TableData from '../../components/TableData/TableData';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import Button from '../../components/Button/Button';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';

export default function SupportPage() {
   const { t } = useTranslation('support');
   const { config } = useContext(UserContext);

   if (!config) return null;

   return (
      <Content>
         <h1>{t('title')}</h1>

         <div className={styles.contact}>
            <div>
               <Button to={`mailto:${config.email}`}>
                  {t('send_message')}
                  <ArrowRightIcon />
               </Button>
            </div>

            <div>
               <Card title={t('contact_info')}>
                  <CardLine>
                     <TableData>
                        <tr>
                           <td>{t('email')}</td>
                           <td>
                              <a target='blank' href={`mailto:${config.email}`}>
                                 {config.email}
                              </a>
                           </td>
                        </tr>
                        <tr>
                           <td>{t('phone')}</td>
                           <td>
                              <a href={`tel:${config.phone}`}>{config.phone}</a>
                           </td>
                        </tr>
                     </TableData>
                  </CardLine>
               </Card>

               {config.openingHours && (
                  <Card title={t('opening_hours')}>
                     <CardLine>
                        <TableData>
                           {[
                              'monday',
                              'tuesday',
                              'wednesday',
                              'thursday',
                              'friday',
                              'saturday',
                              'sunday',
                           ].map((day) => (
                              <tr key={day}>
                                 <td>{t(`days.${day}`)}</td>
                                 <td>
                                    {config.openingHours?.[day as 'monday'] ||
                                       '-'}
                                 </td>
                              </tr>
                           ))}
                        </TableData>
                     </CardLine>
                  </Card>
               )}
            </div>
         </div>
      </Content>
   );
}
