import { useTranslation } from 'react-i18next';
import { ProposalDeal } from '../../types/Deal';
import styles from './ProposalLine.module.scss';
import { formatPrice } from '../../utils/formatPrice';
import CardLine from '../CardLine/CardLine';

interface Props {
   proposal: ProposalDeal;
}

export default function ProposalCard({ proposal }: Props) {
   const { t } = useTranslation('proposals');

   return (
      <CardLine
         to={proposal.linkUrls.viewProposalOnStoreWebsite}
         target='blank'
      >
         <div className={styles.header}>
            <strong>
               {t('proposal_id')} {proposal.proposalDetails.name}
            </strong>

            <strong>
               {formatPrice(
                  proposal.proposalDetails.totalPrice,
                  proposal.proposalDetails.currencyCode
               )}
            </strong>
         </div>

         <div className={styles.content}>
            <div>
               {t('from')} {proposal.proposalDetails.nameOfSalesPerson}
            </div>

            <div>
               {proposal.proposalDetails.numberOfLineItems} {t('elements')}
            </div>
         </div>
      </CardLine>
   );
}
