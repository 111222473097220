import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ConfigToHelmet from '../../utils/configToHelmet';
import { SiteConfig } from '../../types/User';

interface Props {
   config: SiteConfig | null;
   children: React.ReactNode;
}

export default function LoggedOutLayout({ children, config }: Props) {
   const { pathname } = useLocation();

   useEffect(() => {
      window.scrollTo(0, 0);
   }, [pathname]);

   if (!config) {
      return null;
   }

   return (
      <div>
         <ConfigToHelmet config={config} />
         {children}
      </div>
   );
}
