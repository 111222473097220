// Function that formats date to dd/mm/yyyy HH:MM
export const formatDate = (date: string, includeTime?: boolean) => {
   const dateObj = new Date(date);

   if (isNaN(dateObj.getTime())) return date;

   const day = dateObj.getDate().toString().padStart(2, '0');
   const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
   const year = dateObj.getFullYear();

   if (!includeTime) return `${day}.${month}.${year}`;

   const hours = dateObj.getHours().toString().padStart(2, '0');
   const minutes = dateObj.getMinutes().toString().padStart(2, '0');
   return `${day}.${month}.${year} - ${hours}:${minutes}`;
};
