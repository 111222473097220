import { ConversationThread } from '../types/Conversations';
import { useTranslation } from 'react-i18next';

export const getLatestMessageTime = (message: ConversationThread) => {
   const { t } = useTranslation('messages');

   const { latestMessageSentTimestamp, latestMessageReceivedTimestamp } =
      message;

   let latestMessageTime = '';

   if (!latestMessageSentTimestamp && !latestMessageReceivedTimestamp) {
      latestMessageTime = message.createdAt;
   } else if (!latestMessageSentTimestamp) {
      latestMessageTime = latestMessageReceivedTimestamp;
   } else if (!latestMessageReceivedTimestamp) {
      latestMessageTime = latestMessageSentTimestamp;
   } else {
      latestMessageTime =
         new Date(latestMessageSentTimestamp) >
         new Date(latestMessageReceivedTimestamp)
            ? latestMessageSentTimestamp
            : latestMessageReceivedTimestamp;
   }

   const daysSinceLatestMessage = Math.floor(
      (new Date().getTime() - new Date(latestMessageTime).getTime()) /
         (1000 * 60 * 60 * 24)
   );

   if (daysSinceLatestMessage === 0) {
      return t('today');
   } else if (daysSinceLatestMessage === 1) {
      return t('yesterday');
   } else {
      return `${daysSinceLatestMessage} ${t('days_ago')}`;
   }
};
