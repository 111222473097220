import styles from './Alert.module.scss';

interface Props {
   children: React.ReactNode;
   className?: string;
}

export default function Alert({ children, className }: Props) {
   return (
      <div className={`${styles.alert} ${className ? className : ''}`}>
         {children}
      </div>
   );
}
