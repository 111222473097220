import { useTranslation } from 'react-i18next';
import Content from '../../components/Content/Content';
import { UserContext } from '../../contexts/UserContext';
import { useContext } from 'react';
import styles from './ClaimTicketsPage.module.scss';
import ClaimTicketLine from '../../components/ClaimTicketLine/ClaimTicketLine';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/Card';
import { ReactComponent as ClaimIcon } from '../../assets/icons/claim.svg';

export default function ClaimTicketsPage() {
   const { t } = useTranslation('tickets');

   const closedStatuses = ['support-closed', 'technician-case-closed'];

   const { tickets, loadingTickets } = useContext(UserContext);

   const claims = tickets?.claimTickets;

   const ticketsInProgress = claims?.filter(
      (ticket) => !closedStatuses.includes(ticket.status)
   );

   const previousTickets = claims?.filter((ticket) =>
      closedStatuses.includes(ticket.status)
   );

   return (
      <Content>
         <h1 className={styles.title}>
            {t('title')}
            <Button to='/orders/complain'>{t('new_ticket')}</Button>
         </h1>

         <Card
            loading={loadingTickets}
            title={t('tickets_in_progress')}
            icon={<ClaimIcon />}
         >
            {ticketsInProgress != null &&
               ticketsInProgress.length > 0 &&
               ticketsInProgress.map((ticket) => (
                  <ClaimTicketLine key={ticket.id} ticket={ticket} />
               ))}

            {!loadingTickets && !ticketsInProgress?.length && (
               <div>{t('no_tickets_in_progress')}</div>
            )}
         </Card>

         <Card
            loading={loadingTickets}
            title={t('previous_tickets')}
            icon={<ClaimIcon />}
         >
            {previousTickets != null &&
               previousTickets.length > 0 &&
               previousTickets.map((ticket) => (
                  <ClaimTicketLine ticket={ticket} key={ticket.id} />
               ))}

            {!loadingTickets && !previousTickets?.length && (
               <div>{t('no_previous_tickets')}</div>
            )}
         </Card>
      </Content>
   );
}
