import { Helmet } from 'react-helmet';

export default function Chat() {
   const testEnv = ['local', 'staging'];

   const useSandbox = testEnv.some((env) =>
      window.location.hostname.includes(env)
   );

   return (
      <Helmet>
         <script
            type='text/javascript'
            id='hs-script-loader'
            async
            defer
            src={
               useSandbox
                  ? '//js-eu1.hs-scripts.com/143825039.js'
                  : '//js-eu1.hs-scripts.com/143450730.js'
            }
         ></script>
      </Helmet>
   );
}
