import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import { UserContext } from '../../contexts/UserContext';
import { useContext, useState } from 'react';
import { fetchApi } from '../../utils/fetchApi';
import { useParams } from 'react-router-dom';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import styles from './CancelOrderModal.module.scss';
import Loading from '../Loading/Loading';

export default function CancelOrderModal() {
   const { t } = useTranslation('modals');
   let { id } = useParams();

   const [loading, setLoading] = useState(false);
   const [sent, setSent] = useState(false);

   const { user } = useContext(UserContext);

   const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);
      const data = new FormData(e?.target as HTMLFormElement);

      const message = data.get('message') as string;

      await fetchApi(
         `/api/logged-in-user/order-deal/${id}/request-cancellation`,
         {
            method: 'POST',
            body: JSON.stringify({ message }),
         }
      );

      setLoading(false);
      setSent(true);
   };

   if (loading) {
      return <Loading />;
   }

   if (sent) {
      return (
         <div>
            <h2 className={styles.heading}>{t('cancel_order_sent')}</h2>
            <p>{t('cancel_order_sent_text')}</p>
         </div>
      );
   }

   return (
      <form onSubmit={handleSubmit} className={styles.cancelOrderModal}>
         <h2 className={styles.heading}>{t('cancel_order_modal')}</h2>
         <p>{t('cancel_order_text')}</p>

         <label htmlFor='message'>{t('cancel_order_message')}</label>
         <textarea
            id='message'
            disabled={loading}
            name='message'
            placeholder={t('cancel_order_reason')}
         ></textarea>

         <Button disabled={loading} type='submit' block>
            {t('cancel_order_submit')}
            <ArrowRightIcon />
         </Button>
      </form>
   );
}
