import { NavLink } from 'react-router-dom';
import styles from './CardLine.module.scss';

interface Props {
   children: React.ReactNode;
   to?: string;
   target?: string;
}

export default function CardLine({ children, to, target }: Props) {
   if (to)
      return (
         <NavLink
            target={target}
            to={to}
            className={`${styles.cardline} ${styles.link}`}
         >
            {children}
         </NavLink>
      );

   return <div className={styles.cardline}>{children}</div>;
}
