import Layout from './pages/Layout/Layout';
import { UserProvider } from './contexts/UserContext';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import ProposalsPage from './pages/ProposalsPage/ProposalsPage';
import OrdersPage from './pages/OrdersPage/OrdersPage';
import ClaimTicketsPage from './pages/ClaimTicketsPage/ClaimTicketsPage';
import OrderPage from './pages/OrderPage/OrderPage';
import OrdersComplainPage from './pages/OrdersComplainPage/OrdersComplainPage';
import ComplainPage from './pages/ComplainPage/ComplainPage';
import MessagePage from './pages/MessagePage/MessagePage';
import MessageLayout from './pages/MessageLayout/MessageLayout';
import ClaimTicketPage from './pages/ClaimTicketPage/ClaimTicketPage';
import SupportPage from './pages/SupportPage/SupportPage';
import UnauthorizedPage from './pages/UnauthorizedPage/UnauthorizedPage';
import ServerErrorPage from './pages/ServerErrorPage/ServerErrorPage';
import { ModalProvider } from './contexts/ModalsContext';

function App() {
   return (
      <BrowserRouter>
         <Routes>
            <Route
               path='/'
               element={
                  <UserProvider>
                     <ModalProvider>
                        <Layout />
                     </ModalProvider>
                  </UserProvider>
               }
            >
               <Route index element={<HomePage />} />
               <Route path='/proposals' element={<ProposalsPage />} />
               <Route path='/orders' element={<OrdersPage />} />
               <Route
                  path='/orders/complain'
                  element={<OrdersComplainPage />}
               />
               <Route path='/orders/:id' element={<OrderPage />} />
               <Route path='/orders/:id/complain' element={<ComplainPage />} />
               <Route path='/messages' element={<MessageLayout />}>
                  <Route path=':id' element={<MessagePage />} />
               </Route>
               <Route path='/claims' element={<ClaimTicketsPage />} />
               <Route path='/claims/:id' element={<ClaimTicketPage />} />
               <Route path='/claims/:claimId/edit' element={<ComplainPage />} />
               <Route path='/support' element={<SupportPage />} />
            </Route>
            <Route path='/unauthorized' element={<UnauthorizedPage />} />
            <Route path='/server-error' element={<ServerErrorPage />} />
            <Route path='*' element={<NotFoundPage />} />
         </Routes>
      </BrowserRouter>
   );
}

export default App;
