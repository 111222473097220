import { useTranslation } from 'react-i18next';
import { OrderDeal } from '../../types/Deal';
import styles from './OrderLine.module.scss';
import { formatPrice } from '../../utils/formatPrice';
import CardLine from '../CardLine/CardLine';

interface Props {
   order: OrderDeal;
   to?: string;
   disabled?: boolean;
}

export default function OrderLine({ order, to, disabled }: Props) {
   const { t } = useTranslation('orders');

   let link = to ? to : `/orders/${order.id}`;
   if (disabled) {
      link = '';
   }

   return (
      <CardLine to={link}>
         <div className={styles.header}>
            <strong>
               {t('order_number')} {order.orderDetails.orderNumber}
            </strong>
            <strong>
               {formatPrice(
                  order.orderDetails.totalPrice,
                  order.orderDetails.currencyCode
               )}
            </strong>
         </div>

         <div className={styles.content}>
            <div>{t('status_' + order.orderDetails.currentStage)}</div>

            <div>
               {t('paid_with')} {order.orderDetails.paymentMethod}
            </div>
         </div>
      </CardLine>
   );
}
