import styles from './Loading.module.scss';

interface Props {
   large?: boolean;
}

export default function Loading({ large }: Props) {
   return (
      <div className={`${styles.loading} ${large ? styles.large : ''}`}>
         <div className={styles.ball}></div>
         <div className={styles.ball}></div>
      </div>
   );
}
