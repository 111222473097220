import { NavLink } from 'react-router-dom';
import styles from './Card.module.scss';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import Loading from '../Loading/Loading';

interface Props {
   children: React.ReactNode;
   title?: string;
   action?: {
      to?: string;
      onClick?: () => void;
      label: string;
   };
   icon?: React.ReactNode;
   loading?: boolean;
}

export default function Card({
   children,
   title,
   action,
   icon,
   loading,
}: Props) {
   return (
      <div className={styles.card}>
         {(title || action) && (
            <div className={styles.header}>
               <div className={styles.title}>
                  {icon}
                  {title && <h2>{title}</h2>}
               </div>
               {action && (
                  <>
                     {action.to && (
                        <NavLink className={styles.action} to={action.to}>
                           {action.label}
                           <ArrowRightIcon />
                        </NavLink>
                     )}

                     {action.onClick && (
                        <button
                           className={styles.action}
                           onClick={action.onClick}
                        >
                           {action.label}
                        </button>
                     )}
                  </>
               )}
            </div>
         )}

         <div className={styles.content}>
            {loading && (
               <div className={styles.loading}>
                  <Loading />
               </div>
            )}
            {children}
         </div>
      </div>
   );
}
