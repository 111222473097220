import { Helmet } from 'react-helmet';
import { BrandColor, SiteConfig } from '../types/User';

interface Props {
   config: SiteConfig | null;
}

export default function ConfigToHelmet({ config }: Props) {
   if (!config) {
      return null;
   }

   const colors = config.colors;
   const styleString = `
      :root {
         ${
            colors
               ? Object.keys(colors)
                    .map((color) =>
                       colors[color as BrandColor]
                          ? ` --color-${color}: ${colors[color as BrandColor]}; \n`
                          : ''
                    )
                    .join('')
               : ''
         }
         ${config.fontName && `--font-base: '${config.fontName}';\n`}
      }`;

   return (
      <Helmet>
         <title>{config.name}</title>
         <style id='helmet'>{styleString}</style>
         {config.fontLink && <link href={config.fontLink} rel='stylesheet' />}
      </Helmet>
   );
}
