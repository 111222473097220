import { useTranslation } from 'react-i18next';

export default function ComplainReasonLabel({ reason }: { reason: string }) {
   const { t } = useTranslation('error_reasons');

   let key = reason.replace(/ /g, '_').toLowerCase();
   key = key.replace(/æ/g, 'ae');
   key = key.replace(/ø/g, 'oe');
   key = key.replace(/å/g, 'aa');
   key = key.replace(/[^a-zA-Z0-9_]/g, '');

   return (
      <>
         {t(key, {
            defaultValue: reason,
         })}
      </>
   );
}
