import { Outlet, useParams } from 'react-router-dom';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrow-left.svg';
import styles from './MessageLayout.module.scss';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import Button from '../../components/Button/Button';
import { ReactComponent as ChatIcon } from '../../assets/icons/chats.svg';
import MessageListItem from '../../components/MessageListItem/MessageListItem';
import Chat from '../../components/Chat/Chat';

export default function MessageLayout() {
   const { config, conversations } = useContext(UserContext);
   const { t } = useTranslation('messages');

   let { id } = useParams();

   return (
      <>
         <Chat />

         {!!id && (
            <div className={styles.back}>
               <Button to='/messages' small>
                  <ArrowLeftIcon />
                  {t('back')}
               </Button>
            </div>
         )}
         <div className={styles.messages}>
            <div className={`${styles.threads} ${!!id && styles.mobileHidden}`}>
               <div className={styles.header}>
                  <h1>{t('title')}</h1>
                  {config?.email && (
                     <Button target='blank' to={`mailto:${config.email}`} small>
                        {t('new_message')}
                        <ChatIcon />
                     </Button>
                  )}
               </div>

               {(conversations?.conversationThreads?.length || 0) === 0 && (
                  <div>{t('no_messages')}</div>
               )}

               {conversations?.conversationThreads?.map((thread) => (
                  <MessageListItem key={thread.id} message={thread} />
               ))}
            </div>
            <div className={styles.message}>
               <Outlet />
            </div>
         </div>
      </>
   );
}
