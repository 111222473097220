import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetchApi } from '../utils/fetchApi';
import { UserContext } from './UserContext';
import { ClaimImage } from '../types/Tickets';

export type ClaimReasons = {
   [key: number]: ClaimReason;
};

type ClaimReason = {
   elaboratedReason: string;
   claimErrorReasonValues: string[];
   files: File[];
   uploadedFiles?: ClaimImage[];
};

type ComplainContextType = {
   step: number;
   setStep: (step: number) => void;
   selectedOrderLines: number[];
   toggleSelectedOrderLine: (id: number) => void;
   setSelectedOrderLines: (selectedOrderLines: number[]) => void;
   claimReasons: ClaimReasons;
   addClaimReason: (claimReason: ClaimReason, id: number) => void;
   validReasons: string[] | null;
};

export const ComplainContext = createContext<ComplainContextType>({
   step: 1,
   // eslint-disable-next-line no-unused-vars
   setStep: (step: number) => {},
   selectedOrderLines: [],
   // eslint-disable-next-line no-unused-vars
   toggleSelectedOrderLine: (id: number) => {},
   // eslint-disable-next-line no-unused-vars
   setSelectedOrderLines: (selectedOrderLines: number[]) => {},
   claimReasons: {},
   // eslint-disable-next-line no-unused-vars
   addClaimReason: (claimReason: ClaimReason, id: number) => {},
   // eslint-disable-next-line no-unused-vars
   validReasons: [],
});

export const ComplainProvider = ({
   children,
}: {
   children: React.ReactNode;
}) => {
   const preselectedQuery = new URLSearchParams(window.location.search).get(
      'select'
   );
   const preselected = preselectedQuery ? [parseInt(preselectedQuery)] : [];

   const [selectedOrderLines, setSelectedOrderLines] =
      useState<number[]>(preselected);

   const { user } = useContext(UserContext);

   const [step, setStep] = useState(1);

   const [claimReasons, setClaimReasons] = useState<ClaimReasons>({});

   const addClaimReason = (claimReason: ClaimReason, id: number) => {
      setClaimReasons((prev) => ({ ...prev, [id]: claimReason }));
   };

   const [validReasons, setValidReasons] = useState<string[] | null>(null);

   const fetchValidReasons = async () => {
      if (validReasons) return;

      const data = await fetchApi(`/api/logged-in-user/claim-tickets-reasons`);
      setValidReasons(data.data.optionValues);
   };

   useEffect(() => {
      if (step === 2) {
         fetchValidReasons();
      }

      window.scrollTo(0, 0);
   }, [step]);

   const toggleSelectedOrderLine = (id: number) => {
      const newSelected = selectedOrderLines.includes(id)
         ? selectedOrderLines.filter((s) => s !== id)
         : [...selectedOrderLines, id];

      setSelectedOrderLines(newSelected);
   };

   return (
      <ComplainContext.Provider
         value={{
            validReasons,
            claimReasons,
            addClaimReason,
            selectedOrderLines,
            toggleSelectedOrderLine,
            step,
            setStep,
            setSelectedOrderLines,
         }}
      >
         {children}
      </ComplainContext.Provider>
   );
};
