import { useTranslation } from 'react-i18next';

import styles from './ComplainOverviewStep.module.scss';
import { useContext, useState } from 'react';
import { ComplainContext } from '../../../contexts/ComplainContext';

import { UserContext } from '../../../contexts/UserContext';
import { useParams } from 'react-router-dom';
import Button from '../../Button/Button';

import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as CheckmarkIcon } from '../../../assets/icons/checkmark.svg';
import { getOrderLineItemsSelectedIds } from '../../../utils/getOrderLineItemsSelectedIds';
import { fetchApi } from '../../../utils/fetchApi';
import Loading from '../../Loading/Loading';
import ClaimOverview from '../../ClaimOverview/ClaimOverview';

export default function ComplainOverviewStep() {
   const { t } = useTranslation('complain_steps');

   let { id, claimId } = useParams();

   const { lineItems, user, tickets } = useContext(UserContext);

   const claim = tickets?.claimTickets.find(
      (ticket) => ticket.id.toString() === claimId
   );

   const orderId = (
      claim?.idOfAssociatedDeal ? claim.idOfAssociatedDeal : id
   )?.toString();

   if (!orderId || !lineItems) return null;

   const { selectedOrderLines, claimReasons, setStep } =
      useContext(ComplainContext);

   const [status, setStatus] = useState('');
   const [fileCount, setFileCount] = useState(1);

   const orderLineItems = getOrderLineItemsSelectedIds(
      lineItems[orderId],
      selectedOrderLines
   );

   const attachments = Object.keys(claimReasons)
      .map((reason) => {
         return {
            images: claimReasons[parseInt(reason)].files,
            idOfOrderLineItem: reason,
         };
      })
      .filter((attachment) => attachment.images.length > 0);

   const imageCount = attachments.reduce(
      (acc, attachment) => acc + attachment.images.length,
      0
   );

   const handleSubmit = async () => {
      setStatus('submitting');

      const data = {
         idOfOrderDeal: id,
         orderLineItemsClaimIsRegarding: orderLineItems.map((item) => ({
            idOfOrderLineItem: item.id,
            sku: item.sku,
            localizedTitle: item.name,
            claimErrorReasonValues:
               claimReasons[item.id].claimErrorReasonValues,
            elaboratedReason: claimReasons[item.id].elaboratedReason,
         })),
      };

      const reqUrl = claimId
         ? `/api/logged-in-user/claim-ticket/${claimId}/edit`
         : `/api/logged-in-user/create-claim-ticket`;

      const response = await fetchApi(reqUrl, {
         method: 'POST',
         body: JSON.stringify(data),
      });

      const ticketId = claimId ? claimId : response.data.createdClaimTicket.id;

      if (attachments.length > 0) {
         setStatus('uploading');
         await Promise.all(
            attachments.map(async (attachment) => {
               await Promise.all(
                  attachment.images.map(async (image, index) => {
                     const formData = new FormData();

                     formData.append(`images[]`, image);

                     formData.append(
                        'idOfOrderLineItem',
                        attachment.idOfOrderLineItem
                     );

                     await fetchApi(
                        `/api/logged-in-user/create-claim-ticket/${ticketId}/attach-images`,
                        {
                           headers: {},
                           method: 'POST',
                           body: formData,
                        }
                     );
                     setFileCount(fileCount + 1);
                  })
               );
            })
         );
      }

      setStep(4);
   };

   if (status) {
      return (
         <div className={styles.status}>
            <Loading />
            {status === 'uploading' ? (
               <p>
                  {t('uploading', {
                     count: fileCount,
                     total: imageCount,
                  })}
               </p>
            ) : (
               <p>{t(status)}</p>
            )}
         </div>
      );
   }

   return (
      <div className={styles.content}>
         <h2>{t('selected_elements')}:</h2>

         <div className={styles.reasons}>
            {orderLineItems.map((item) => (
               <ClaimOverview
                  key={item.id}
                  name={item.name}
                  reasons={claimReasons[item.id].claimErrorReasonValues}
                  description={claimReasons[item.id].elaboratedReason}
                  images={[
                     ...(claimReasons[item.id].uploadedFiles?.map(
                        (x) => x.tempUrl || ''
                     ) || []),
                     ...claimReasons[item.id].files.map((file) =>
                        URL.createObjectURL(file)
                     ),
                  ]}
               />
            ))}
         </div>

         <div className={styles.actions}>
            <Button variant='ghost' onClick={() => setStep(2)}>
               <ArrowLeftIcon />
               {t('go_back')}
            </Button>
            <Button onClick={handleSubmit}>
               {t('submit_complain')}
               <CheckmarkIcon />
            </Button>
         </div>
      </div>
   );
}
