import { Link, useParams } from 'react-router-dom';

import styles from './MessageLine.module.scss';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/formatDate';
import { ConversationThread } from '../../types/Conversations';
import CardLine from '../CardLine/CardLine';
import { getLatestMessageTime } from '../../utils/getLatestMessageTime';

interface MessageLineProps {
   message: ConversationThread;
}

export default function MessageLine({ message }: MessageLineProps) {
   return (
      <CardLine key={message.id} to={`/messages/${message.id}`}>
         <strong>
            #{message.id}: {message.status.toLowerCase()}
         </strong>

         <div className={styles.content}>
            <div>{formatDate(message.createdAt, true)}</div>
            <div className={styles.info}>{getLatestMessageTime(message)}</div>
         </div>
      </CardLine>
   );
}
