import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts/UserContext';
import { useContext } from 'react';
import OrderLine from '../../components/OrderLine/OrderLine';
import Loading from '../../components/Loading/Loading';
import Content from '../../components/Content/Content';
import Card from '../../components/Card/Card';
import { ReactComponent as OrderIcon } from '../../assets/icons/order.svg';
import CardLine from '../../components/CardLine/CardLine';

export default function OrdersPage() {
   const { t } = useTranslation('orders');

   const { deals, loadingDeals } = useContext(UserContext);

   const orders = deals?.orderDeals;

   const ordersInProgress = orders?.filter(
      (order) => order.orderDetails.currentStage === 'Pending'
   );

   const cancelledOrders = orders?.filter(
      (order) => order.orderDetails.currentStage === 'Canceled'
   );

   const specialOrders = ['Pending', 'Canceled'];

   const previousOrders = orders?.filter(
      (order) => !specialOrders.includes(order.orderDetails.currentStage)
   );

   return (
      <Content>
         <h1>{t('title')}</h1>

         {loadingDeals ? (
            <Loading />
         ) : (
            <div>
               {(orders?.length || 0) == 0 && (
                  <h2>{t('no_orders_in_progress')}</h2>
               )}

               {(ordersInProgress?.length || 0) > 0 && (
                  <Card title={t('orders_in_progress')} icon={<OrderIcon />}>
                     {ordersInProgress?.map((order) => (
                        <OrderLine key={order.id} order={order} />
                     ))}
                  </Card>
               )}

               {(previousOrders?.length || 0) > 0 && (
                  <Card title={t('previous_orders')} icon={<OrderIcon />}>
                     {previousOrders?.map((order) => (
                        <OrderLine key={order.id} order={order} />
                     ))}
                  </Card>
               )}

               {(cancelledOrders?.length || 0) > 0 && (
                  <Card title={t('cancelled_orders')} icon={<OrderIcon />}>
                     {cancelledOrders?.map((order) => (
                        <OrderLine disabled key={order.id} order={order} />
                     ))}
                  </Card>
               )}
            </div>
         )}
      </Content>
   );
}
