import { useTranslation } from 'react-i18next';
import Progress from '../../Progress/Progress';

interface Props {
   activeStep: number;
}

export default function ComplainProgress({ activeStep }: Props) {
   const { t } = useTranslation('complain_progress');

   const steps = [
      {
         title: t('order'),
         description: t('order_description'),
      },
      {
         title: t('elements'),
         description: t('elements_description'),
      },
      {
         title: t('description'),
         description: t('description_description'),
      },
      {
         title: t('submit'),
         description: t('submit_description'),
      },
   ];

   return <Progress steps={steps} activeIndex={activeStep} />;
}
