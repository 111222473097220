import styles from './Progress.module.scss';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/checkmark-rounded.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';

interface Step {
   title: string;
   description?: string;
}

interface Props {
   steps: Step[];
   activeIndex: number;
}

export default function Progress({ steps, activeIndex }: Props) {
   return (
      <div className={styles.progess}>
         {steps.map((step, index) => (
            <div
               key={index}
               className={`${styles.step} ${activeIndex < index ? styles.disabled : ''}`}
            >
               <CheckmarkIcon />
               <strong>{step.title}</strong>
               {step.description && (
                  <div className={styles.description}>{step.description}</div>
               )}

               {index < steps.length - 1 && (
                  <span className={styles.arrow}>
                     <ArrowRightIcon />
                  </span>
               )}
            </div>
         ))}
      </div>
   );
}
