import { useContext } from 'react';
import ComplainProgress from '../ComplainProgress/ComplainProgress';
import { ComplainContext } from '../../../contexts/ComplainContext';
import ComplainElementsStep from '../ComplainElementsStep/ComplainElementsStep';
import ComplainDescriptionStep from '../ComplainDescriptionStep/ComplainDescriptionStep';
import { useTranslation } from 'react-i18next';
import ComplainOverviewStep from '../ComplainOverviewStep/ComplainOverviewStep';
import ComplainCompletedStep from '../ComplainCompletedStep/ComplainCompletedStep';
import { useParams } from 'react-router-dom';

export default function ComplainFlow() {
   const { step } = useContext(ComplainContext);

   let { claimId } = useParams();

   const { t } = useTranslation('complain_flow');

   const getStep = (step: number) => {
      switch (step) {
         case 1:
            return <ComplainElementsStep />;
         case 2:
            return <ComplainDescriptionStep />;
         case 3:
            return <ComplainOverviewStep />;
         case 4:
            return <ComplainCompletedStep />;
         default:
            return null;
      }
   };

   return (
      <div>
         <h1>{t(`title_${claimId ? `edit_${step}` : step}`)}</h1>
         {step !== 4 && <ComplainProgress activeStep={step} />}
         {getStep(step)}
      </div>
   );
}
