import React, { createContext, useEffect, useState } from 'react';
import { SiteConfig, User } from '../types/User';
import { Deals, OrderFiles } from '../types/Deal';
import { fetchApi } from '../utils/fetchApi';
import { LineItems } from '../types/LineItems';
import { ClaimImage, Tickets } from '../types/Tickets';
import { Conversations, ConversationsThreads } from '../types/Conversations';
import useSiteConfig from '../hooks/useSiteConfig';

type UserContextType = {
   user: User | null;
   config: SiteConfig | null;
   deals: Deals | null;
   tickets: Tickets | null;
   lineItems: { [key: string]: LineItems } | null;
   orderFiles: { [key: string]: OrderFiles } | null;
   conversations: Conversations | null;
   conversationThreads: ConversationsThreads;
   loading: boolean;
   loadingFiles: boolean;
   loadingLineItems: boolean;
   loadingDeals: boolean;
   loadingTickets: boolean;
   loadingConversations: boolean;
   fetchOrderLineItems: (id: string, force?: boolean) => void;
   fetchOrderFiles: (id: string, pageNumber?: number, force?: boolean) => void;
   fetchClaimTicketLineItems: (id: string, force?: boolean) => void;
   fetchClaimTicketImages: (id: string, force?: boolean) => void;
   fetchTickets: (force?: boolean) => void;
   fetchConversations: (force?: boolean) => void;
   fetchConversationThreads: (id: string, force?: boolean) => void;
};

export const UserContext = createContext<UserContextType>({
   user: null,
   config: null,
   deals: null,
   tickets: null,
   lineItems: null,
   orderFiles: null,
   conversations: null,
   conversationThreads: {},
   loading: true,
   loadingFiles: false,
   loadingDeals: false,
   loadingTickets: false,
   loadingLineItems: false,
   loadingConversations: false,
   fetchOrderLineItems: () => {},
   fetchOrderFiles: () => {},
   fetchClaimTicketLineItems: () => {},
   fetchClaimTicketImages: () => {},
   fetchTickets: () => {},
   fetchConversations: () => {},
   fetchConversationThreads: () => {},
});

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
   /*
    ** USER
    */
   const [user, setUser] = useState<User | null>(null);

   const fetchUser = async () => {
      if (user) return;

      const data = await fetchApi(`/api/logged-in-user`);

      if (data.data) {
         setUser(data.data);
      }
   };

   /*
    ** CONFIG
    */

   const { config } = useSiteConfig();

   /*
    ** DEALS
    */
   const [deals, setDeals] = useState<Deals | null>(null);
   const [loadingDeals, setLoadingDeals] = useState(false);

   const fetchDeals = async (force?: boolean) => {
      if (deals && !force) return;
      setLoadingDeals(true);
      const data = await fetchApi(`/api/logged-in-user/deals`);
      setDeals(data.data);
      setLoadingDeals(false);
   };

   /*
    ** LINE ITEMS
    */
   const [lineItems, setLineItems] = useState<{
      [key: string]: LineItems;
   } | null>(null);

   const [loadingLineItems, setLoadingLineItems] = useState(false);

   const fetchOrderLineItems = async (id: string, force?: boolean) => {
      if (lineItems && lineItems[id] && !force) return;

      setLoadingLineItems(true);

      const data = await fetchApi(
         `/api/logged-in-user/deals/${id}/related-line-items`
      );

      setLineItems((prev) => ({
         ...prev,
         [id]: data.data.lineItemsRelatedToOrderDeal,
      }));

      setLoadingLineItems(false);
   };

   /*
    ** FILES
    */
   const [orderFiles, setOrderFiles] = useState<{
      [key: string]: OrderFiles;
   } | null>(null);
   const [loadingFiles, setLoadingFiles] = useState(false);

   const fetchOrderFiles = async (
      id: string,
      page: number = 1,
      force?: boolean
   ) => {
      if (orderFiles && orderFiles[id]?.files[page] && !force) return;

      setLoadingFiles(true);

      const data = await fetchApi(
         `/api/logged-in-user/deals/${id}/related-files/${page}`
      );

      const {
         pageNumber,
         totalNumberOfPages,
         totalNumberOfFiles,
         pageOfFiles,
      } = data.data;

      setOrderFiles((prev) => ({
         ...prev,
         [id]: {
            files: {
               ...prev?.[id]?.files,
               [pageNumber]: pageOfFiles,
            },
            pagination: {
               pageNumber,
               totalNumberOfPages,
               totalNumberOfFiles,
               nextPageOfFiles: data.links.nextPageOfFiles,
            },
         },
      }));

      setLoadingFiles(false);
   };

   /*
    ** TICKETS
    */
   const [tickets, setTickets] = useState<Tickets | null>(null);
   const [loadingTickets, setLoadingTickets] = useState(false);

   const fetchTickets = async (force?: boolean) => {
      if (tickets && !force) return;
      setLoadingTickets(true);
      const data = await fetchApi(`/api/logged-in-user/tickets`);
      setTickets(data.data);
      setLoadingTickets(false);
   };

   const fetchClaimTicketLineItems = async (id: string, force?: boolean) => {
      if (
         tickets?.claimTickets.find((ticket) => ticket.id.toString() === id)
            ?.lineItems &&
         !force
      )
         return;

      const data = await fetchApi(`/api/logged-in-user/claim-ticket/${id}`);

      setTickets((prev) => {
         if (!prev) return null;

         return {
            ...prev,
            claimTickets: prev.claimTickets.map((ticket) => {
               if (ticket.id.toString() === id) {
                  return {
                     ...ticket,
                     idOfAssociatedDeal:
                        data.data?.claimTicket?.idOfAssociatedDeal,
                     lineItems: data.data?.lineItemsRelatedToClaimTicket,
                  };
               }

               return ticket;
            }),
         };
      });
   };

   const fetchClaimTicketImages = async (id: string, force?: boolean) => {
      if (
         tickets?.claimTickets.find((ticket) => ticket.id.toString() === id)
            ?.images &&
         !force
      )
         return;

      const data = await fetchApi(
         `/api/logged-in-user/claim-ticket/${id}/attached-images`
      );

      const ticketImages = data.data.files as ClaimImage[];

      ticketImages.forEach(async (req) => {
         const response = await fetchApi(
            req.actions.getUrlForRetrievingTemporaryUrl.replace(
               'http://',
               'https://'
            )
         );

         setTickets((prev) => {
            if (!prev) return null;

            return {
               ...prev,
               claimTickets: prev.claimTickets.map((ticket) => {
                  if (ticket.id.toString() === id) {
                     const lineItemsWithImages = [
                        ...(ticket.lineItems || []),
                     ].map((x) => {
                        if (
                           x.idOfOrderLineItem.toString() !==
                           req.idOfOrderLineItem
                        )
                           return x;

                        return {
                           ...x,
                           images: [
                              ...(x.images || []),
                              {
                                 ...req,
                                 tempUrl: response.data.signedUrl,
                              },
                           ],
                        };
                     });

                     return {
                        ...ticket,
                        images: ticketImages,
                        lineItems: lineItemsWithImages,
                     };
                  }
                  return ticket;
               }),
            };
         });
      });
   };

   /*
    ** CONVERSATIONS
    */
   const [conversations, setConversations] = useState<Conversations | null>(
      null
   );
   const [conversationThreads, setConversationThreads] =
      useState<ConversationsThreads>({});
   const [loadingConversations, setLoadingConversations] = useState(false);

   const fetchConversations = async (force?: boolean) => {
      if (conversations && !force) return;
      setLoadingConversations(true);
      const data = await fetchApi(`/api/logged-in-user/conversations`);

      setConversations(data.data);
      setLoadingConversations(false);
   };

   const fetchConversationThreads = async (id: string, force?: boolean) => {
      if (conversationThreads[id] && !force) return;

      const data = await fetchApi(
         `/api/logged-in-user/conversations/${id}/messages`
      );
      setConversationThreads((prev) => ({
         ...prev,
         [id]: data.data.conversationThreadMessages,
      }));
   };

   const [loading, setLoading] = useState(true);

   /*
    ** EFFECTS
    */
   useEffect(() => {
      if (
         !!user &&
         !loadingDeals &&
         !!deals &&
         !loadingTickets &&
         !!tickets &&
         !loadingConversations &&
         !!conversations
      ) {
         setLoading(false);
      }
   }, [user, loadingDeals, loadingTickets, loadingConversations]);

   useEffect(() => {
      if (!user) return;

      fetchDeals();
      fetchTickets();
      fetchConversations();
   }, [user]);

   useEffect(() => {
      fetchUser();
   }, []);

   return (
      <UserContext.Provider
         value={{
            config,
            tickets,
            user,
            loading,
            deals,
            loadingDeals,
            loadingLineItems,
            lineItems,
            orderFiles,
            loadingFiles,
            fetchTickets,
            fetchOrderLineItems,
            fetchOrderFiles,
            fetchClaimTicketImages,
            fetchClaimTicketLineItems,
            loadingTickets,
            conversations,
            fetchConversations,
            fetchConversationThreads,
            conversationThreads,
            loadingConversations,
         }}
      >
         {children}
      </UserContext.Provider>
   );
};
