import { useTranslation } from 'react-i18next';
import { ReactComponent as ClaimIcon } from '../../../assets/icons/claim.svg';

import styles from './ComplainCompletedStep.module.scss';
import Button from '../../Button/Button';
import { UserContext } from '../../../contexts/UserContext';
import { useContext, useEffect } from 'react';

export default function ComplainCompletedStep() {
   const { t } = useTranslation('complain_steps');

   const { fetchTickets } = useContext(UserContext);

   useEffect(() => {
      fetchTickets(true);
   }, []);

   return (
      <div>
         <p className={styles.text}>{t('completed_text')}</p>
         <Button to='/claims'>
            {t('see_complaints')}
            <ClaimIcon />
         </Button>
      </div>
   );
}
