import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts/UserContext';
import { useContext } from 'react';
import OrderCard from '../../components/OrderCard/OrderCard';
import styles from './OrdersComplainPage.module.scss';
import ComplainProgress from '../../components/Complains/ComplainProgress/ComplainProgress';
import Button from '../../components/Button/Button';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import Loading from '../../components/Loading/Loading';
import Content from '../../components/Content/Content';
import Card from '../../components/Card/Card';
import { ReactComponent as OrderIcon } from '../../assets/icons/order.svg';
import OrderLine from '../../components/OrderLine/OrderLine';
import CardLine from '../../components/CardLine/CardLine';

export default function OrdersComplainPage() {
   const { t } = useTranslation('orders_complain');

   const { deals, loadingDeals } = useContext(UserContext);
   const orders = deals?.orderDeals;

   return (
      <Content>
         <h1>{t('title')}</h1>

         <ComplainProgress activeStep={0} />

         <Card title={t('orders')} loading={loadingDeals} icon={<OrderIcon />}>
            {deals?.orderDeals &&
               deals.orderDeals.length > 0 &&
               deals.orderDeals.map((order) => (
                  <OrderLine
                     to={`/orders/${order.id}/complain`}
                     key={order.id}
                     order={order}
                  />
               ))}

            {!deals?.orderDeals && !loadingDeals && (
               <CardLine>{t('no_orders')}</CardLine>
            )}
         </Card>
      </Content>
   );
}
