import { Link, useParams } from 'react-router-dom';

import styles from './MessageListItem.module.scss';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/formatDate';
import { ConversationThread } from '../../types/Conversations';
import { getLatestMessageTime } from '../../utils/getLatestMessageTime';

interface MessageListItemProps {
   message: ConversationThread;
}

export default function MessageListItem({ message }: MessageListItemProps) {
   let { id } = useParams();
   const { t } = useTranslation('messages');

   return (
      <Link
         key={message.id}
         className={`${styles.thread} ${id === message.id.toString() && styles.active}`}
         to={`/messages/${message.id}`}
      >
         <div className={styles.title}>
            #{message.id}: {t(`status.${message.status.toLowerCase()}`)}
         </div>
         <div className={styles.footer}>
            <div>
               <strong>{t('created_at')}</strong>:{' '}
               {formatDate(message.createdAt, true)}
            </div>
            <div className={styles.info}>{getLatestMessageTime(message)}</div>
         </div>
      </Link>
   );
}
