'use client';

import { ReactNode, useContext, useEffect } from 'react';

import styles from './Modal.module.scss';
import { ModalsContext } from '../../contexts/ModalsContext';

interface Props {
   content: ReactNode | null;
}

export function Modal({ content }: Props) {
   const { close } = useContext(ModalsContext);

   useEffect(() => {
      if (!content) return;

      const handleEscape = (e: KeyboardEvent) => {
         if (!content) return;

         if (e.key === 'Escape') {
            close();
         }
      };

      window.addEventListener('keydown', handleEscape);

      return () => {
         window.removeEventListener('keydown', handleEscape);
      };
   }, [close]);

   useEffect(() => {
      if (content) {
         document.body.style.overflow = 'hidden';
      } else {
         document.body.style.overflow = 'auto';
      }
   }, [content]);

   if (!content) return null;

   return (
      <div className={styles.modal}>
         <button
            type='button'
            className={styles.backdrop}
            onClick={() => close()}
         ></button>

         <div className={styles.wrapper}>
            <div className={styles.content}>
               <button
                  type='button'
                  className={styles.close}
                  onClick={() => close()}
               >
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
                     <path
                        d='M13.4,12l6.3-6.3c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3
	c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
	c0.4-0.4,0.4-1,0-1.4L13.4,12z'
                     />
                  </svg>
               </button>
               {content}
            </div>
         </div>
      </div>
   );
}
