import { useTranslation } from 'react-i18next';
import { OrderFile } from '../../types/Deal';
import CardLine from '../CardLine/CardLine';
import styles from './FileLine.module.scss';

interface Props {
   file: OrderFile;
}

export default function FileLine({ file }: Props) {
   const { t } = useTranslation('orders');

   return (
      <CardLine>
         <div className={styles.file}>
            {file.filenameForUser}

            {file.links.signedUrlForFile && (
               <a
                  className={styles.download}
                  href={file.links.signedUrlForFile}
                  target='_blank'
                  rel='noreferrer'
               >
                  {t('download')}
               </a>
            )}
         </div>
      </CardLine>
   );
}
