import styles from './ToolTip.module.scss';

interface Props {
   text: string;
   children: React.ReactNode;
}

export default function ToolTip({ children, text }: Props) {
   if (!text) {
      return <>{children}</>;
   }

   return (
      <span className={styles.wrapper}>
         <span className={styles.tooltip}>{text}</span>
         {children}
      </span>
   );
}
