import styles from './BackButton.module.scss';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
import { NavLink } from 'react-router-dom';

interface Props {
   children?: React.ReactNode;
   to: string;
}

export default function BackButton({ children, to }: Props) {
   return (
      <NavLink to={to} className={styles.backbutton}>
         <ArrowLeft />
         {children}
      </NavLink>
   );
}
